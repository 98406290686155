export class StickyNavTabs {
  constructor(overviewCategoriesPreview) {
    this.overviewCategoriesPreview = overviewCategoriesPreview;
    this.setupEventListeners();
  }

  setupEventListeners() {
    const tabEls = this.overviewCategoriesPreview.querySelectorAll('[data-bs-toggle="tab"]');
    [...tabEls].forEach((tabEl) => {
      tabEl.addEventListener('show.bs.tab', (e) => {
        const activatedTab = e.target;

        activatedTab.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      });
    });

    const navTabsList = document.querySelector('.nav-tabs-sticky');
    const prevBtn = document.querySelector('[data-sticky-nav-tab-prev]');
    const nextBtn = document.querySelector('[data-sticky-nav-tab-next]');
    prevBtn.addEventListener('click', (e) => {
      const newScrollPosition = navTabsList.scrollLeft - 200;
      navTabsList.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });

      this.preloadPreviousImages();
    });
    nextBtn.addEventListener('click', (e) => {
      const newScrollPosition = navTabsList.scrollLeft + 200;
      navTabsList.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });

      this.preloadNextImages();
    });

    const navTabsListActiveItem = document.querySelector('.nav-tabs-sticky .nav-link.active');
    navTabsList.scrollLeft = navTabsListActiveItem.offsetLeft - (navTabsList.offsetWidth - navTabsListActiveItem.offsetWidth) / 2;

    const navTabsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target.dataset.stickyNavTabsRef === 'start') {
          this.overviewCategoriesPreview.classList.toggle('at-start', entry.isIntersecting);
        }
        if (entry.target.dataset.stickyNavTabsRef === 'end') {
          this.overviewCategoriesPreview.classList.toggle('at-end', entry.isIntersecting);
        }
      });
    });

    document
      .querySelectorAll('[data-sticky-nav-tabs-ref]')
      .forEach((startEnd) => navTabsObserver.observe(startEnd));
    navTabsObserver.observe(navTabsList);
  }

  preloadPreviousImages() {
    // preload previous few images
    const allImages = [...this.overviewCategoriesPreview.querySelectorAll(
      '[data-lazy="true"]'
    )];
    const loadedImages = [...this.overviewCategoriesPreview.querySelectorAll(
      '[data-loaded="true"]'
    )];
    const lastLoadedImage = loadedImages[loadedImages.length - 1];
    const lastLoadedImageIndex = loadedImages.indexOf(lastLoadedImage);
    if (allImages[lastLoadedImageIndex - 1]) {
      window.lazyLoadObserver.triggerLoad(allImages[lastLoadedImageIndex - 1]);
    }
    if (allImages[lastLoadedImageIndex - 2]) {
      window.lazyLoadObserver.triggerLoad(allImages[lastLoadedImageIndex - 2]);
    }
    if (allImages[lastLoadedImageIndex - 3]) {
      window.lazyLoadObserver.triggerLoad(allImages[lastLoadedImageIndex - 3]);
    }
    if (allImages[lastLoadedImageIndex - 4]) {
      window.lazyLoadObserver.triggerLoad(allImages[lastLoadedImageIndex - 4]);
    }
  }

  preloadNextImages() {
    // preload next few images
    const allImages = [...this.overviewCategoriesPreview.querySelectorAll(
      '[data-lazy="true"]'
    )];
    const loadedImages = [...this.overviewCategoriesPreview.querySelectorAll(
      '[data-loaded="true"]'
    )];
    const lastLoadedImage = loadedImages[loadedImages.length - 1];
    const lastLoadedImageIndex = loadedImages.indexOf(lastLoadedImage);
    if (allImages[lastLoadedImageIndex + 1]) {
      window.lazyLoadObserver.triggerLoad(allImages[lastLoadedImageIndex + 1]);
    }
    if (allImages[lastLoadedImageIndex + 2]) {
      window.lazyLoadObserver.triggerLoad(allImages[lastLoadedImageIndex + 2]);
    }
    if (allImages[lastLoadedImageIndex + 3]) {
      window.lazyLoadObserver.triggerLoad(allImages[lastLoadedImageIndex + 3]);
    }
    if (allImages[lastLoadedImageIndex + 4]) {
      window.lazyLoadObserver.triggerLoad(allImages[lastLoadedImageIndex + 4]);
    }
  }
}
