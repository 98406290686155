import * as CookieConsent from 'vanilla-cookieconsent';

setTimeout(() => {
  const isLocalhost = window.location.href.indexOf('localhost') > -1;
  const baseDomain = isLocalhost ? 'localhost' : 'enterreg';

  const isTCBS = window.location.href.indexOf('thecarbuyingshop') > -1;
  const isERTrade =
    window.location.href.indexOf(baseDomain) > -1 && window.location.href.indexOf('/trade') > -1;
  const isERPublic = !isTCBS && !isERTrade && window.location.href.indexOf(baseDomain) > -1;

  let termsAndConditionsUrl;
  let privacyPolicyUrl;
  let targetAndRel = '';

  if (isTCBS || isERTrade || isERPublic) {
    termsAndConditionsUrl = isTCBS ? '/terms-and-conditions' : '/terms';
    privacyPolicyUrl = isTCBS ? '/privacy-policy' : '/privacy';
  } else {
    // otherwise it's a business website, so point to ER Trade Terms & Public Privacy pages
    termsAndConditionsUrl = 'https://www.enterreg.com/trade/terms';
    privacyPolicyUrl = 'https://www.enterreg.com/privacy';
    targetAndRel = 'target="_blank" rel="noopener"';
  }

  CookieConsent.run({
    guiOptions: {
      consentModal: {
        layout: 'box',
        position: 'bottom left',
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: 'bar',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        enabled: true,
        readOnly: true,
      },
      functionality: {
        enabled: true,
      },
      analytics: {
        enabled: true,
      },
      marketing: {
        enabled: true,
      },
    },
    language: {
      default: 'en',
      autoDetect: 'browser',
      translations: {
        en: {
          consentModal: {
            title: false,
            description:
              'This website uses cookies to ensure you get the best experience on our website.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Only necessary',
            showPreferencesBtn: 'Manage preferences',
            footer: `<a href="${privacyPolicyUrl}" ${targetAndRel}>Privacy</a>\n<a href="${termsAndConditionsUrl}" ${targetAndRel}>Terms</a>`,
          },
          preferencesModal: {
            title: 'Consent Preferences Center',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Only necessary',
            savePreferencesBtn: 'Save preferences',
            closeIconLabel: 'Close modal',
            serviceCounterLabel: 'Service|Services',
            sections: [
              {
                title: 'Cookie Usage',
                description:
                  'We use cookies to enhance your experience on our website. By continuing to browse, you agree to our use of cookies. You can choose to accept or manage your cookie preferences below.',
              },
              {
                title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                description:
                  'These cookies are essential for the website to function properly. They enable basic features like page navigation and secure areas. Without these cookies, the site cannot operate efficiently.',
                linkedCategory: 'necessary',
              },
              {
                title: 'Functionality Cookies',
                description:
                  'Functionality cookies allow the website to remember choices you make, such as your language or region, to provide enhanced and personalized features.',
                linkedCategory: 'functionality',
              },
              {
                title: 'Analytics Cookies',
                description:
                  'Analytics cookies help us understand how visitors interact with our site by collecting and reporting information anonymously. This data helps us improve website performance and user experience.',
                linkedCategory: 'analytics',
              },
              {
                title: 'Advertisement Cookies',
                description:
                  'Advertisement cookies are used to deliver relevant ads and marketing campaigns. They track visitors across websites to display ads that are meaningful to you.',
                linkedCategory: 'marketing',
              },
              {
                title: 'More information',
                description:
                  'If you want to find out more about our cookie policy, please head to the <a class="cc__link" href="/cookies">cookies</a> page.',
              },
            ],
          },
        },
      },
    },
  });
}, 1000);
