import { Controller } from '@hotwired/stimulus';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import Modal from 'bootstrap/js/dist/modal';

export default class extends Controller {
  static targets = ['gridWrapper'];

  static values = {};

  connect() {
    const lightboxInstance = this.initLightbox();
    if (lightboxInstance) {
      this.lightboxOpen = false;

      lightboxInstance.on('tapAction', (e) => {
        const target = e?.originalEvent?.target;
        if (target) {
          const shouldClose =
            target.matches('.pswp__item') &&
            !target.matches('.pswp__img') &&
            !target.matches('.pswp__top-bar');

          if (shouldClose) {
            lightboxInstance.pswp.close();
          }
        }
      });

      lightboxInstance.on('openingAnimationStart', () => {
        this.lightboxOpen = true;
      });
      lightboxInstance.on('close', () => {
        this.lightboxOpen = false;

        const closeBtn = this.element.querySelector('[data-bs-dismiss="modal"]');
        setTimeout(() => {
          // focus the close button so that pressing on ESC key triggers modal to close
          closeBtn.focus();
        }, 0);
      });
    }

    this.element.addEventListener('shown.bs.modal', (e) => {
      const gridElements = this.gridWrapperTarget.querySelectorAll('.stock-gallery-grid-item');
      const triggerThumbIndex = e.relatedTarget.dataset.slideIndex;
      gridElements[triggerThumbIndex].scrollIntoView({ block: 'center', behavior: 'smooth' });
    });

    // when BS prevents modal from being closed using keyboard, check if it should actually allow it (when Photoswipe gallery isn't visible)
    this.element.addEventListener('hidePrevented.bs.modal', (e) => {
      if (!this.lightboxOpen) {
        const galleryModal = Modal.getOrCreateInstance(this.element);
        galleryModal.hide();
      }
    });
  }

  initLightbox() {
    if (!this.hasGridWrapperTarget) {
      return null;
    }

    const lightbox = new PhotoSwipeLightbox({
      gallery: this.gridWrapperTarget,
      children: 'a',
      pswpModule: PhotoSwipe,
      showHideAnimationType: 'fade',
      preload: [1, 3],
      wheelToZoom: true,
      preloaderDelay: 200,
      maxZoomLevel: 1.5,
    });

    lightbox.init();
    return lightbox;
  }
}
