import './shared/rails';

// eslint-disable-next-line import/no-unresolved
import '@client-side-validations/client-side-validations/src';
// eslint-disable-next-line import/no-unresolved
import '@client-side-validations/simple-form/src/index.bootstrap4';

import '@hotwired/turbo-rails';

// Import Bootstrap
import Dropdown from 'bootstrap/js/dist/dropdown';
import Collapse from 'bootstrap/js/dist/collapse';
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Tab from 'bootstrap/js/dist/tab';
import Modal from 'bootstrap/js/dist/modal';

// Import Photoswipe
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

// Import jQuery
import './jquery';
import autosize from 'autosize';

// import Stimulus controllers
import './controllers/public';

// import './admin/get-make-logo.js.erb';

// Import components
import './shared/address-lookup';
import './shared/scroll-to';
import './shared/lazy-load';
import { initAllTooltips } from './shared/util';
import { Form } from './shared/form';
import { ImageUpload } from './shared/image-upload';
import { ColorPicker } from './shared/color-picker';
import { Collapse as ERCollapse } from './shared/collapse';
import { Datepicker } from './shared/datepicker';

import { DirectoryListingsPreview } from './public/directory-listings-preview';
import { StickyNavTabs } from './public/sticky-nav-tabs';

// disable Turbo default no-reload page switching
Turbo.session.drive = false;

const loadNonCriticalFeaturesWhenIdle = () => {
  // These can load after the page is interactive
  const rIC = 'requestIdleCallback' in window ? requestIdleCallback : setTimeout;
  const options = 'requestIdleCallback' in window ? {} : 5000;
  rIC(() => {
    Promise.all([import('./shared/cookie-consent'), import('./shared/browser-update')]).catch(
      console.error
    );
  }, options);
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', loadNonCriticalFeaturesWhenIdle);
} else {
  loadNonCriticalFeaturesWhenIdle();
}

document.addEventListener('DOMContentLoaded', () => {
  // initialize tooltips
  const tooltips = initAllTooltips();

  // initialize date/time pickers
  if (document.querySelectorAll('.js-date-picker').length) {
    const dateTimePickers = document.querySelectorAll('.js-date-picker');
    if (dateTimePickers.length) {
      [...dateTimePickers].map((dateTimePicker) => new Datepicker(dateTimePicker));
    }
  }

  // initialize automatic resizing of textareas
  autosize(document.querySelectorAll('textarea'));

  // initialize image upload
  const imageUploadWrappers = document.querySelectorAll('.js-image-upload');
  if (imageUploadWrappers.length) {
    [...imageUploadWrappers].map((imageUploadWrapper) => new ImageUpload(imageUploadWrapper));
  }

  // initialize forms
  if (document.querySelectorAll('.js-form').length) {
    const forms = document.querySelectorAll('.js-form');
    if (forms.length) {
      [...forms].map((form) => new Form(form));
    }
  }

  // initialize color pickers
  if (document.querySelectorAll('.js-color-picker').length) {
    const colorPickers = document.querySelectorAll('.js-color-picker');
    if (colorPickers.length) {
      [...colorPickers].map((colorPicker) => new ColorPicker(colorPicker));
    }
  }

  // initialize collapse previews
  if (document.querySelectorAll('.js-collapse-wrapper').length) {
    const previewCollapseWrappers = document.querySelectorAll('.js-collapse-wrapper');
    if (previewCollapseWrappers.length) {
      [...previewCollapseWrappers].map(
        (previewCollapseWrapper) => new ERCollapse(previewCollapseWrapper)
      );
    }
  }

  // initialize quick preview drawer/offcanvas
  const directoryListingsPreviewWrapper = document.querySelector('[data-directory-listings-preview]');
  if (directoryListingsPreviewWrapper) {
    const directoryListingsPreview = new DirectoryListingsPreview(directoryListingsPreviewWrapper);
  }

  // initialize sticky-nav-tabs
  if (document.querySelector('[data-sticky-nav-tabs]')) {
    const stickyNavTabs = new StickyNavTabs(document.querySelector('[data-sticky-nav-tabs]'));
  }

  // initialize offers chat wrapper & the chat gallery
  const offersChatWrapper = document.querySelector('[data-offers-chat-wrapper]');
  if (offersChatWrapper) {
    offersChatWrapper.scrollTop = offersChatWrapper.scrollHeight;

    setTimeout(() => {
      offersChatWrapper.scrollTop = offersChatWrapper.scrollHeight;
    }, 500);

    setTimeout(() => {
      offersChatWrapper.scrollTop = offersChatWrapper.scrollHeight;
    }, 1500);

    const lightbox = new PhotoSwipeLightbox({
      gallery: offersChatWrapper,
      children: 'a',
      pswpModule: PhotoSwipe,
      showHideAnimationType: 'fade',
      preload: [1, 3],
      wheelToZoom: true,
      preloaderDelay: 200,
      maxZoomLevel: 1.5,
    });
    lightbox.init();
  }
});
